import { createStore } from "vuex";
import costCenterStore from "./financial/costcenter";
import loginStore from "./login";
import financialAccountStore from "./financial/financialAccount";
import meanOfPaymentStore from "./financial/meanofpayment";
import bankStore from "./financial/bank";
import typeOfPaymentMethodStore from "./financial/typeofpaymentmethod";
import financialCategoryStore from "./financial/financialCategory";
import supplierTypeStore from "./material/suppliertype";
import brandStore from "./material/brand";
import serviceStore from "./commercial/service";
import checklistStore from "./commercial/checklist";
import unitStore from "./administration/unit";
import businessGroupStore from "./administration/businessgroup";
import contactTypeStore from "./shared/contacttype";
import addressStore from "./shared/address";
import cityStore from "./shared/city";
import stateStore from "./shared/state";
import documentTypeStore from "./shared/documenttype";
import issuingAuthorityStore from "./shared/issuingauthority";
import civilStatusStore from "./shared/civilstatus";
import genderStore from "./shared/gender";
import personaltreatmentStore from "./shared/personaltreatment";
import professionStore from "./shared/profession";
import profileStore from "./administration/profile";
import employeeStore from "./administration/employee";
import customerStore from "./customer";
import supplierStore from "./material/supplier";
import productStore from "./material/product";
import productTypeStore from "./shared/producttype";
import productGenreStore from "./shared/productgenre";
import productOriginStore from "./shared/productorigin";
import unitOfMeasurementStore from "./shared/unitofmeasurement";
import purchaseStore from "./material/purchase";
import inventoryStore from "./material/inventory";
import productOrderStore from "./material/productorder";
import cashManagementStore from "./financial/cashmanagement";
import accountPayableStore from "./financial/accountpayable";
import accountReceivableStore from "./financial/accountreceivable";
import transferAccountStore from "./financial/transferaccount";
import personStore from "./shared/person";
import consignedStore from "./commercial/consigned";
import loanStore from "./commercial/loan";
import saleStore from "./commercial/sale/product";
import testStore from "./commercial/test";
import voucherStore from "./commercial/voucher";
import workOrderStore from "./commercial/workorder/customer";
import dashboardStore from "./dashboard";
import blockStore from "./schedule/block";
import exceptionStore from "./schedule/exception";
import reasonStore from "./schedule/reason";
import timeTableStore from "./schedule/timetable";
import medicalCertificateStore from "./medicalconsultation/medicalcertificate";
import partnershipReportStore from "./medicalconsultation/partnershipreport";
import patientRecordStore from "./medicalconsultation/patientrecord";
import prescriptionStore from "./medicalconsultation/prescription";
import partnershipStore from "./billing/partnership";
import performingPhysicianStore from "./billing/performingphysician";
import requestingPhysicianStore from "./billing/requestingphysician";
import fiscalStore from "./commercial/fiscal";
import treatmentStore from "./commercial/treatment";
import batchStore from "./billing/batch";
import consultationGuideStore from "./billing/consultationguide";
import otherGuideStore from "./billing/otherguides";
import spsadtGuideStore from "./billing/spsadtguide";
import taskStore from "./others/task";
import stockTransferStore from "./material/stocktransfer";
import passingOnStore from "./financial/passingon";
import scheduleStore from "./schedule/schedule";
import loadingStore from "./shared/loading";
import workOrderInternalStore from "./commercial/workorder/internal";
import invoiceProductStore from "./commercial/invoice/product";
import taxationStore from "./commercial/invoice/taxation";
import invoiceConsumerStore from "./commercial/invoice/consumer";
import saleConsumerStore from "./commercial/sale/consumer";
import taxScenarioStore from "./commercial/invoice/taxscenario";

export default createStore({
  modules: {
    login: loginStore,
    costCenter: costCenterStore,
    financialAccount: financialAccountStore,
    meanOfPayment: meanOfPaymentStore,
    bank: bankStore,
    typeOfPaymentMethod: typeOfPaymentMethodStore,
    financialCategory: financialCategoryStore,
    supplierType: supplierTypeStore,
    brand: brandStore,
    service: serviceStore,
    checklist: checklistStore,
    unit: unitStore,
    businessGroup: businessGroupStore,
    contactType: contactTypeStore,
    address: addressStore,
    city: cityStore,
    state: stateStore,
    documentType: documentTypeStore,
    issuingAuthority: issuingAuthorityStore,
    civilStatus: civilStatusStore,
    gender: genderStore,
    personalTreatment: personaltreatmentStore,
    profession: professionStore,
    profile: profileStore,
    employee: employeeStore,
    customer: customerStore,
    supplier: supplierStore,
    product: productStore,
    productType: productTypeStore,
    productGenre: productGenreStore,
    productOrigin: productOriginStore,
    unitOfMeasurement: unitOfMeasurementStore,
    purchase: purchaseStore,
    inventory: inventoryStore,
    productOrder: productOrderStore,
    cashManagement: cashManagementStore,
    accountPayable: accountPayableStore,
    accountReceivable: accountReceivableStore,
    transferAccount: transferAccountStore,
    person: personStore,
    consigned: consignedStore,
    loan: loanStore,
    sale: saleStore,
    saleConsumer: saleConsumerStore,
    test: testStore,
    voucher: voucherStore,
    workorder: workOrderStore,
    workorderInternal: workOrderInternalStore,
    dashboard: dashboardStore,
    block: blockStore,
    exception: exceptionStore,
    reason: reasonStore,
    timeTable: timeTableStore,
    medicalCertificate: medicalCertificateStore,
    partnershipReport: partnershipReportStore,
    patientRecord: patientRecordStore,
    prescription: prescriptionStore,
    partnership: partnershipStore,
    performingPhysician: performingPhysicianStore,
    requestingPhysician: requestingPhysicianStore,
    fiscal: fiscalStore,
    treatment: treatmentStore,
    batch: batchStore,
    consultationGuide: consultationGuideStore,
    otherGuide: otherGuideStore,
    spsadtGuide: spsadtGuideStore,
    task: taskStore,
    stockTransfer: stockTransferStore,
    passingOn: passingOnStore,
    schedule: scheduleStore,
    loading: loadingStore,
    invoiceProduct: invoiceProductStore,
    taxation: taxationStore,
    invoiceConsumer: invoiceConsumerStore,
    taxScenario: taxScenarioStore,
  },
});
