import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  WorkOrder,
  WorkOrderState,
} from "@/models/interfaces/commercial/workorder.interface";
import { WorkorderService as service } from "@/services/commercial/workorder/customer";
import { constants } from "@/constants/commercial/workorder";

export const actions: ActionTree<WorkOrderState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: WorkOrder[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_WORKORDERS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: WorkOrder) => {
      commit(constants.SET_WORKORDER, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: WorkOrder[] = await service.insert(state.workorder);
    commit(constants.INSERT_WORKORDER, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: WorkOrder[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_WORKORDERS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: WorkOrder[] = await service.update(id, state.workorder);
    commit(constants.UPDATE_WORKORDER, res);
  },
};
