import { TaxationService } from "@/services/commercial/taxation.services";
import { ProductService } from "@/services/material/product.services";
import { getTaxation } from "./taxation";
import { getCfop } from "./taxation/cfop.service";

export const getItems = async (
  items: any,
  cenario: any,
  recipient: any,
  consumidorFinal: boolean
) => {
  const itens: any[] = [];
  let total = 0;

  for (const item of items) {
    const product: any = await ProductService.getById(item.ProdutoId);
    const taxation: any = await TaxationService.getByCenarioNcm(
      cenario,
      product.Ncm ? product.Ncm : 0
    );
    const codigoBeneficioFiscal = getCodigoBeneficioFiscal(taxation, product);

    const descontoUnitario = +(item.ValorDesconto / item.Quantidade).toFixed(2);

    const valorUnitario = item.Valor - descontoUnitario;

    const valor = +(valorUnitario * item.Quantidade).toFixed(2);

    itens.push({
      codigo: product.Id.toString(),
      descricao: product.Nome,
      ncm: product.Ncm,
      ...(product.Cest && { cest: product.Cest }),
      ...(codigoBeneficioFiscal && { codigoBeneficioFiscal }),
      cfop: getCfop(taxation, product, recipient),
      valor: valor,
      valorUnitario: {
        comercial: valorUnitario,
        tributavel: valorUnitario,
      },
      quantidade: { comercial: item.Quantidade, tributavel: item.Quantidade },
      tributos: await getTaxation(
        product,
        taxation,
        item,
        recipient,
        consumidorFinal,
        valor
      ),
    });

    total += +valor;
  }

  const itemsTreated = { itens, total };

  /* await items.reduce(
    async (acc: any, item: any) => {
      const product: any = await ProductService.getById(item.ProdutoId);

      const taxation: any = await TaxationService.getByCenarioNcm(
        cenario,
        product.Ncm ? product.Ncm : 0
      );

      const codigoBeneficioFiscal = getCodigoBeneficioFiscal(taxation, product);

      acc.itens.push({
        codigo: product.Id.toString(),
        descricao: product.Nome,
        ncm: product.Ncm,

        ...(product.Cest && { cest: product.Cest }),

        ...(codigoBeneficioFiscal && { codigoBeneficioFiscal }),

        //criar funcao para recuperar cfop
        cfop: getCfop(taxation, product, recipient), // taxation.CfopId.toString(),

        valor: item.ValorTotal,

        valorUnitario: {
          comercial: item.Valor,
          tributavel: item.Valor,
        },

        tributos: await getTaxation(
          product,
          taxation,
          item,
          recipient,
          consumidorFinal
        ),
      });

      acc.total += item.ValorTotal;

      return acc;
    },
    { itens: [], total: 0 }
  );
 */
  return itemsTreated;
};

const getCodigoBeneficioFiscal = (taxation: any, product: any) => {
  const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
  return ncms[0].CodigoBeneficioFiscal;
};
