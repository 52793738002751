/* import { onMounted } from "vue"; */
import axios from "axios";
import { API_URL } from "@/constants/env";
import store from "@/store";

import processFilters from "./processFilters";

interface Browsing {
  lastFilters: any[];
  lastSearchText: string;
  lastPage: number;
  lastUrl: string;
  wait: number;
}

export const useGetAll = (
  page: number,
  searchText: string,
  lastFilters: any[],
  functionality: string
) => {
  const fetchData = async (): Promise<any[]> => {
    const credential = { ...store.state.login.credential };
    const browsing: Browsing = { ...store.state.login.browsing };

    browsing.lastFilters = lastFilters;
    browsing.lastSearchText = searchText;
    browsing.lastPage = page;
    browsing.lastUrl = "";
    browsing.wait = 0;
    store.state.login.browsing = browsing;

    const { active, recordtype, moreFilter } = processFilters(lastFilters);

    const recordType = recordtype ? recordtype : active;

    const employeeOnline = `&profissionalLogado=${credential.userId}`;

    const urlDefault = `${API_URL}/${functionality}?cliente=${credential.tenantId}&pos=${page}&tipoRegistro=${recordType}&filtro=${searchText}${moreFilter}&ordernar=`;

    const needIdEmployeeOnline = [
      "paciente",
      "receita",
      "despesa",
      "transferencia",
      "teste",
      "emprestimo",
      "ordemservico",
      "ordemservico/getinterno",
      "venda",
      "caixa",
      "cupomdesconto",
      "nfe",
      "boleto",
      "caixa",
    ];

    const url =
      needIdEmployeeOnline.indexOf(functionality) + 1
        ? urlDefault + employeeOnline
        : urlDefault;

    const response = await axios.get(url);

    return response.data;
  };

  // Chame fetchData quando o componente for montado
  /* onMounted(() => {
    fetchData();
  }); */

  // Retorne a função fetchData e outros dados ou funções que você deseja expor
  return {
    fetchData,
    // Outros dados ou funções aqui...
  };
};
