import { API_URL } from "@/constants/env";
import store from "@/store";
import axios from "axios";

export const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> => {
  const credential = { ...store.state.login.credential };
  const browsing = await { ...store.state.login.browsing };
  browsing.lastFilters = lastFilters;
  browsing.lastSearchText = searchText;
  browsing.lastPage = page;
  store.state.login.browsing = browsing;

  let active = "TODOS";

  for (let i = 0; i < lastFilters?.length; i++) {
    const line = lastFilters[i];

    for (let j = 0; j < lastFilters?.length; j++) {
      const field = line[j];

      if (field.field == "Ativo") {
        active = field.model;
      }
    }
  }

  const url =
    `${API_URL}/ordemservico/getinterno?cliente=${credential.tenantId}&pos=${page}` +
    `&tipoRegistro=${active}&filtro=${searchText}&profissionalLogado=${credential.userId}`;

  return (await axios.get(url)).data;
};
