import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import {
  iColumn,
  iDataTable,
  iFilter,
} from "@/components/shared/interface/Column.Inteface";
import { ACTIVE } from "@/helpers/filters";

export default function useList(
  functionality: string,
  fields: iColumn[],
  filters: iFilter[][] | undefined
) {
  const store = useStore();
  const datatableRef = ref();

  const stateLoading = computed(() => store.state.loading).value;

  const fieldsConfig: iDataTable = {
    datatable: fields,
    filter: filters,
  };

  const filter = async (params: object) => {
    await getAll(params);
  };

  const getAll = async (params: object) =>
    store.dispatch(`${functionality}/getAll`, params);

  const remove = (ids: string[]) =>
    store.dispatch(`${functionality}/remove`, ids);

  const changeStatusActive = (ids: any[]) => {
    store.dispatch(`${functionality}/changeStatusActive`, ids);
    datatableRef.value.selectedItems = [];
  };

  const page = async (event: any) => {
    const lastFilters = event.lastFilters;

    const params = {
      page: event.page + 1,
      searchText: event.searchText,
      lastFilters,
    };

    await getAll(params);
    datatableRef.value.loading = false;
  };

  const state = computed(() => store.state[functionality]).value;

  onMounted(async () => {
    stateLoading.loading.value = true;
    const page = 0;
    const searchText = "";
    const active = ACTIVE;
    active.field = "Ativo";
    active.model = ref("Ativo");

    const moreFilter = filters ? filters[0] : undefined;

    const lastFilters = moreFilter
      ? ref([[...moreFilter]]).value
      : ref([[active]]);

    const params = { page, searchText, lastFilters };

    await getAll(params);
    stateLoading.loading.value = false;
  });

  const descActive = ref("Inativar");
  const treattFilter = (params: object) => {
    filter(params);

    const browsing = { ...store.state.login.browsing };

    descActive.value =
      browsing.lastFilters[0].find(
        (value: { field: string }) => value.field == "Ativo"
      ).model.value == "Ativo"
        ? "Inativar"
        : "Ativar";
  };

  const loading = ref(true);

  watch(
    () => datatableRef.value?.loading,
    () => {
      loading.value = datatableRef.value?.loading;
    }
  );

  const selectedItems = ref([]);

  watch(
    () => datatableRef.value?.selectedItems,
    () => {
      selectedItems.value = datatableRef.value?.selectedItems;
    }
  );

  return {
    datatableRef,
    fields: fieldsConfig,
    filter,
    getAll,
    remove,
    page,
    state,
    descActive,
    treattFilter,
    changeStatusActive,
    loading,
    selectedItems,
  };
}
